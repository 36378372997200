// extracted by mini-css-extract-plugin
export var altBackground = "#eee";
export var background = "#fcfcfc";
export var border = "#ccc";
export var footerLink = "#a22";
export var footerLinkHover = "#c44";
export var foreground = "#210";
export var invertedBackground = "#222";
export var link = "#26b";
export var linkHover = "#48f";
export var off1 = "#677";
export var off2 = "#dcc";
export var off3 = "#edd";
export var readableLineCharacterWidth = "40rem";
export var subBackground = "#faf8f6";
export var subheader = "blog-post-module--subheader--ad9b4";
export var datePublished = "blog-post-module--datePublished--ea569";
export var content = "blog-post-module--content--77cb2";
export var postNavList = "blog-post-module--postNavList--094e6";